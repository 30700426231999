.grid-articles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, calc(32%) calc(32%) calc(32%));
  overflow-y: scroll;
  gap: 24px; }
  @media (min-width: 768px) {
    .grid-articles {
      grid-template-columns: repeat(3, 1fr); } }

.cardnews {
  transition: all .2s linear; }
  .cardnews a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    background: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 8px 32px -12px rgba(33, 46, 99, 0.08);
    transition: all .2s linear; }
    @media (min-width: 768px) {
      .cardnews a {
        padding: 24px;
        align-items: start; } }
    .cardnews a .cardnews__image {
      flex: 1 0 auto;
      background: #ffffff;
      border: 1px solid #edeef3;
      border-radius: 8px;
      margin-right: 24px;
      max-width: 12rem;
      height: 96px;
      padding: 8px; }
      @media (max-width: 768px) {
        .cardnews a .cardnews__image {
          width: 96px;
          height: 96px; } }
      @media (min-width: 768px) {
    .cardnews a .cardnews__image {
      width: 12.0rem;
      height: 180px; } }
      .cardnews a .cardnews__image img {
        width: 100%;
        height: 100%; }
    .cardnews a .cardnews__body {
      flex: 0 1 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .cardnews a .cardnews__body .cardnews__content {
        display: flex;
        flex-direction: column; }
        .cardnews a .cardnews__body .cardnews__content .cardnews__title {
          color: #212e63;
          font-size: 1.125rem;
          font-weight: medium;
          line-height: 25px;
          margin-top: 0;
          margin-bottom: 8px; }
        .cardnews a .cardnews__body .cardnews__content .cardnews__prices {
          position: relative;
          display: flex;
          flex-direction: column;
          margin-bottom: 16px; }
          .cardnews a .cardnews__body .cardnews__content .cardnews__prices .promotionalprice {
            color: #7981a1;
            font-size: 0.8125rem;
            font-weight: normal;
            line-height: 18px;
            margin-bottom: 4px;
            text-decoration: line-through; }
          .cardnews a .cardnews__body .cardnews__content .cardnews__prices .price {
            color: #212e63;
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 25px; }
          .cardnews a .cardnews__body .cardnews__content .cardnews__prices .badge-price {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 4px 8px;
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 1;
            color: #fff;
            background-color: #E83C4D;
            border-radius: 4px; }
        .cardnews a .cardnews__body .cardnews__content .cardnews__expert {
          color: #212e63;
          font-size: 0.875rem;
          font-weight: normal;
          line-height: 19px;
          margin-bottom: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden; }
    .cardnews a .site-offer {
      display: flex;
      font-size: 1.2rem;
      color: #7a82a2;
      align-items: center; }
      .cardnews a .site-offer svg {
        margin-right: 6px;
      }
  .cardnews:hover {
    transform: translateY(-3px); }
    .cardnews:hover a {
      box-shadow: 0px 8px 24px -8px rgba(33, 46, 99, 0.24); }

.widget {
  margin-bottom: 40px; }
  .widget-category .category-menu {
    list-style: none;
    margin: 0;
    padding: 0; }
    .widget-category .category-menu .cat-item a {
      display: block;
      background-color: #fff;
      border-radius: 16px;
      padding: 16px 24px;
      border: 1px solid #e8ebf7;
      font-weight: 600;
      color: #212E63; }
      .widget-category .category-menu .cat-item a:hover {
        color: #E83C4D; }
  .widget-follow .social-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border-radius: 16px;
    padding: 24px;
    border: 1px solid #e8ebf7; }
    .widget-follow .social-menu .social-item {
      margin-bottom: 24px; }
      .widget-follow .social-menu .social-item a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #212E63;
        margin-bottom: 0; }
        .widget-follow .social-menu .social-item a .icone {
          flex: 0 1 auto;
          width: 44px;
          margin-right: 16px; }
          .widget-follow .social-menu .social-item a .icone path {
            fill: #212E63; }
        .widget-follow .social-menu .social-item a:hover {
          color: #E83C4D; }
          .widget-follow .social-menu .social-item a:hover .icone path {
            fill: #E83C4D; }
      .widget-follow .social-menu .social-item:last-child {
        margin-bottom: 0; }
.btn-item a:hover {
  background-color: #fff;
  border: none;
  color: #E83C4D;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
}
.loading-gif {
  width: 7%;
}
.paginacao {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px; }
  .paginacao .page-item {
    flex: 0 1 auto;
    margin-right: 8px; }
    .paginacao .page-item button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #212E63;
      border-radius: 32px;
      border: none;
    }
      .paginacao .page-item button:hover {
        background-color: #fff;
        color: #E83C4D; }
        .paginacao .page-item button:hover svg path {
          fill: #E83C4D; }
    .paginacao .page-item:last-child {
      margin-right: 0; }
    .paginacao .page-item.active button {
      background-color: #E83C4D;
      color: #fff;
      border: none;
    }

.filters-bar {
  display: flex;
  justify-content: space-between; }
  .filters-bar .categories-filter {
    min-width: 55%; }
    @media (min-width: 768px) {
      .filters-bar .categories-filter {
        min-width: 280px; } }

#footer {
  background-color: #fff;
  width: 100%;
  min-height: 100px;
  padding: 40px 0; }
  #footer .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center; }
    @media (min-width: 768px) {
      #footer .container {
        flex-direction: row; } }
  #footer .brand-footer img {
    height: 56px;
    margin-bottom: 24px; }
  @media (min-width: 768px) {
    #footer .brand-footer img {
      margin-bottom: 0; } }
  #footer .widget {
    margin-bottom: 0; }
    #footer .widget .social-menu {
      border-radius: 0 !important;
      border: 0;
      padding: 0;
      display: flex; }
      #footer .widget .social-menu li {
        margin-right: 32px;
        margin-bottom: 0; }
        #footer .widget .social-menu li a svg {
          margin-right: 8px; }
        #footer .widget .social-menu li:last-child {
          margin-right: 0; }
